.dx-datagrid-headers {
  background-color: #009cde !important;
  color: white;
  cursor: pointer;
  font-weight: bold;
  
}

.dx-datagrid .dx-header-row td:hover *{
  color: #d2d5d7;
}

.dx-datagrid .dx-header-filter {  
  color: black;  
}  

.dx-datagrid .dx-header-filter-empty {  
  color:#d2d5d7;  
} 

.dx-scrollbar-vertical .dx-scrollbar-hoverable {
  width: 15px;
}

.dx-scrollbar-vertical .dx-scrollable-scroll {
  width: 15px;
}

.dx-scrollbar-horizontal .dx-scrollbar-hoverable {
  height: 15px;
}

.dx-scrollbar-horizontal .dx-scrollable-scroll {
  height: 15px;
}