
.dx-datagrid-headers {
  background-color: #009cde !important;
  color: black;
  cursor: pointer;
  font-weight: bold; 
}

.dx-datagrid .dx-header-row td:hover *{
  color: #d2d5d7;
}

.dx-datagrid .dx-header-filter {  
  color: black;  
}  

.dx-datagrid .dx-header-filter-empty {  
  color:#d2d5d7;  
} 

.dx-scrollbar-vertical .dx-scrollbar-hoverable {
  width: 15px;
}

.dx-scrollbar-vertical .dx-scrollable-scroll {
  width: 15px;
}

.dx-scrollbar-horizontal .dx-scrollbar-hoverable {
  height: 15px;
}

.dx-scrollbar-horizontal .dx-scrollable-scroll {
  height: 15px;
}

.dx-datagrid-content .dx-datagrid-table .dx-row>td, .dx-datagrid-content .dx-datagrid-table .dx-row>tr>td {
  vertical-align: middle;
}

.dx-widget {
  font-family:  'Prelo-Book';
  font-size: 16px;
}

#page_header {
  font-size: 16px;
  font-family:  'Prelo-Book';
  padding-left: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #d1d3d3;
}
